<template>
  <layout-horizontal>
    <router-view v-if="account" />
    <connect-wallet
      v-else
    />
  </layout-horizontal>
</template>

<script>
import { mapGetters } from 'vuex'
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue'
import { $themeConfig } from '@themeConfig'
import ConnectWallet from '../../views/components/connect-wallet/connect-wallet.vue'

export default {
  components: {
    LayoutHorizontal,
    ConnectWallet,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
  computed: {
    ...mapGetters({
      account: 'wallet/getAccount',
    }),
  },
}
</script>
