<template>
  <div>
    <b-nav-item
      v-if="metamaskNotInstalled"
      href="https://metamask.io/">
      <b-link
        href="https://metamask.io/"
        target="_blank"
        variant="outline-primary"
      >
        {{ $t('Install metamask') }}
      </b-link>
    </b-nav-item>

    <b-button
      v-if="!metamaskNotInstalled"
      class="mr-1"
      size="sm"
      variant="flat-primary"
      @click="initConnect()"
    >{{ $t("Connect Wallet") }}</b-button>
  </div>
</template>

<script>
import { BNavItem, BButton, BLink } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
    BButton,
    BLink,
  },
  data() {
    return {
    }
  },
  computed: {
    metamaskNotInstalled() {
      return !(window.ethereum && window.ethereum.isMetaMask === true)
    },
  },
  created() {
  },
  methods: {
    initConnect() {
      this.$root.$emit('initConnect', {})
    },
  },
}
</script>

<style scoped>

</style>
