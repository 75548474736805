<template>
  <b-row>
    <b-col
      cols="12"
      class="text-center"
    >
      <h3 class="mb-1">
        {{ $t("Please, connect your wallet") }}
      </h3>
      <h5 class="mb-2">
        {{ $t("Connect your wallet to access all our web 3.0 features") }}
      </h5>

      <b-button
        v-if="!metamaskNotInstalled"
        variant="gradient-primary"
        @click="initConnect()"
      >
        {{ $t('Connect Wallet') }}
      </b-button>

      <b-link
        v-else
        href="https://metamask.io/"
        target="_blank"
        variant="outline-primary"
      >
        <b-button
          variant="gradient-warning"
        >
          {{ $t('Install metamask') }}
        </b-button>
      </b-link>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BLink,
  },
  computed: {
    metamaskNotInstalled() {
      return !(window.ethereum && window.ethereum.isMetaMask === true)
    },
  },
  methods: {
    initConnect() {
      this.$root.$emit('initConnect', true)
    },
  },
}
</script>
