export default [
  {
    header: 'Dashboards',
    icon: 'HomeIcon',
    children: [
      {
        title: 'eCommerce',
        route: 'dashboard',
        icon: 'ShoppingCartIcon',
      },
    ],
  },
]
